input[type="file"]{
    width: 100%;
}
.FieldDateRange__group-label {
    font-weight: 700;
    margin-bottom: .3rem;
}

.FieldDateRange__fields {
    display: flex;
    flex-wrap: wrap;
}

.FieldDateRange__label {
    flex: 1 0 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 38px;
}

.FieldDateRange__field {
    flex: 4 0 100px;
}

.FilterPanel__actions {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
}

.SearchBox {
  position: relative;

  .SearchBox__icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0 10px;
    height: 38px;
  }
}

.SubmissionsPage__search {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  .SearchBox {
    flex: 4 0 300px;
    margin-right: 10px;
  }
}

.DownloadAll{
  float: right;
}

.bot-msg {
  background: #eee;
  border-radius: 5px;
  color: red;
  margin-left: 2%;
  padding: 5px;  
}

.submit-container {
  align-items: baseline;
  display: flex;
  height: 35px;
}
