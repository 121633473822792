p{
    line-height: 1.5;
}
a {
    text-decoration: none;
    color: hsl(232, 50%, 25%);
    // background-color: white;
}
a:hover,
a:focus {
    text-decoration: underline;
}